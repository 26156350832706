export const CepNumberTransform = (cepNumber: string): string => {
    // Remove todos os caracteres que não são números
    const numeros = cepNumber.replace(/\D/g, "");

    // Limita a entrada a no máximo 8 dígitos
    const numerosLimitados = numeros.slice(0, 8);

    // Formata o CEP, adicionando um traço após os 5 primeiros dígitos
    let cepFormatado = numerosLimitados.length > 5
        ? `${numerosLimitados.slice(0, 5)}-${numerosLimitados.slice(5)}`
        : numerosLimitados;

    // Remove o traço se estiver no final
    if (cepFormatado.endsWith('-') && cepFormatado.length === 6) {
        cepFormatado = cepFormatado.slice(0, 5);
    }

    return cepFormatado;
};
