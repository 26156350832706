export const NumberTransform = (numero: string): string => {
    const numeros = numero.replace(/\D/g, "");

    const numerosLimitados = numeros.slice(0, 11);

    if (numerosLimitados.length <= 2) {
        return numerosLimitados;
    }

    if (numerosLimitados.length <= 7) {
        return numerosLimitados.replace(/(\d{2})(\d{0,5})/, "($1)$2");
    }
    
    return numerosLimitados.replace(/(\d{2})(\d{5})(\d{0,4})/, "($1)$2-$3");
};
