import React, { useState, useCallback, useEffect } from 'react';
import { LayoutAdministration } from "../../../shared/layout";
import { Button, TextField, Typography, Box, useTheme, Icon } from '@mui/material';
import { useForm } from "react-hook-form";
import { Editor } from "@tinymce/tinymce-react";
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { EndPointPostagem } from '../../../shared/services/API/connection/Postagens';
import { useNavigate, useParams } from 'react-router-dom';
import { ExceptionError } from '../../../shared/services';
import { SnackbarTransform, URLTransform } from '../../../shared/utils';

type TFormPostagem = {
    id?: number | null;
    nameCreator?: string;
    title: string;
    resumo: string;
    content: string;
    tag: string;
    imageLink: string;
    link?: string;
}

// Definindo o schema de validação Yup fora do componente
const postValidation = Yup.object().shape({
    title: Yup
        .string().min(12, "O título deve ter pelo menos 12 caracteres.")
        .max(120, "O título pode ter no máximo 120 caracteres.")
        .required("Campo título é obrigatório."),
    resumo: Yup
        .string().min(35, "O resumo da postagem tem que ter no mínimo 35 caracteres.")
        .required("O Resumo da postagem é um campo obrigatório."),
    content: Yup
        .string().min(500, "O mínimo de caracteres do conteúdo da postagem deve ser de 500.")
        .required("O Campo de conteúdo é obrigatório."),
    tag: Yup
        .string()
        .required("O campo tag é obrigatório."),
    imageLink: Yup
        .string().url("O link da imagem deve ser válido.")
        .min(50, "A imagem precisa ter um link válido.")
        .max(2000, "A imagem precisa ter um link menor que 2000 caracteres.")
        .required("O campo de imagem é obrigatório."),
});

export const CriarEditarPost = React.memo(({ titlePage, nameUser }: { titlePage: string, nameUser: string }) => {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<TFormPostagem>({
        resolver: yupResolver(postValidation),
        defaultValues: { id: null, tag: "", link: "" }
    });

    const navigate = useNavigate();
    
    const theme = useTheme();
    const { id } = useParams<"id">();
    const [capturaPostagem, setCapturaPostagem] = useState<TFormPostagem>();
    
    // States
    const [adicionaTag, setAdicionaTag] = useState(""); 
    const [tags, setTags] = useState<string[]>([]);
    const [tagError, setTagError] = useState<string | null>(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [snackbarData, setSnackbarData] = useState<{ message: string, success: boolean } | null>(null);

    // Caso seja uma edição de postagem.
    useEffect(() => {
        if (id !== undefined && id !== null) {
            EndPointPostagem.getPostId(Number(id)).then((dadosCapturados) => {
                if (dadosCapturados instanceof ExceptionError) {
                    alert("Lista de postagens não foi encontrada, tente mais tarde!");
                } else {
                    setCapturaPostagem(dadosCapturados);
                    setValue("id", dadosCapturados.id);
                    setValue("title", dadosCapturados.title);
                    setValue("resumo", dadosCapturados.resumo);
                    setValue("content", dadosCapturados.content);
                    setValue("tag", dadosCapturados.tag);
                    setTags(dadosCapturados.tag.split(", "));
                    setValue("imageLink", dadosCapturados.imageLink);
                    setValue("link", URLTransform(dadosCapturados.title));
                }
            });
        }
    }, [id, setValue]);

    // Função para adicionar tags ao array
    const arrayTags = useCallback(() => {
        if (adicionaTag.length < 2 || adicionaTag.length > 35) {
            setTagError("Cada tag deve ter entre 2 e 35 caracteres.");
            return;
        }
        setTags(prevTags => [...prevTags, adicionaTag]);
        setAdicionaTag("");
        setTagError(null);
        
        const updatedTags = [...tags, adicionaTag];
        const tagsString = finalizaTag(updatedTags);
        setValue('tag', tagsString, { shouldValidate: true });
    }, [adicionaTag, tags, setValue]);

    // Função para remover tags do array
    const removeTags = useCallback((index: number) => {
        setTags(prevTags => prevTags.filter((_, i) => i !== index));
    }, []);

    // Função que converte array de tags em string separada por ", "
    const finalizaTag = (arrayTag: string[]): string => arrayTag.join(', ');

    // Função de envio do formulário
    const handleSave = handleSubmit(async (validaDados: TFormPostagem) => {
        if (isSubmitting) return; // Previne múltiplos envios
        setIsSubmitting(true);

        try {
            const tagsString = finalizaTag(tags);
            setValue('tag', tagsString, { shouldValidate: true });

            let result;
            
            if (id) {
                // Editar postagem existente
                result = await EndPointPostagem.postEditPostagem({
                    id: Number(id),
                    nameCreator: nameUser,
                    title: validaDados.title,
                    resumo: validaDados.resumo,
                    content: validaDados.content,
                    tag: validaDados.tag,
                    imageLink: validaDados.imageLink,
                    link: URLTransform(validaDados.title)
                });
            }
            else {
                // Criar nova postagem
                result = await EndPointPostagem.postPostagem({
                    nameCreator: nameUser,
                    title: validaDados.title,
                    resumo: validaDados.resumo,
                    content: validaDados.content,
                    tag: validaDados.tag,
                    imageLink: validaDados.imageLink,
                    link: URLTransform(validaDados.title)
                });
            }
            
            setSnackbarData(result);
            navigate("/administracao/minhas-postagens");
        }
        catch (error) {
            console.error("Erro ao enviar postagem:", error);
        }
        finally {
            setIsSubmitting(false);
        }
    });

    return (
        <LayoutAdministration title={titlePage}>
            <form onSubmit={handleSave}>
                <TextField
                    {...register("title")}
                    label="Título do post"
                    error={!!errors.title}
                    helperText={errors.title?.message}
                    sx={{ width: "100%", marginBottom: 4 }}
                />

                <TextField
                    {...register("resumo")}
                    label="Resumo da postagem"
                    error={!!errors.resumo}
                    helperText={errors.title?.message}
                    sx={{ width: "100%", marginBottom: 4 }}
                />

                <Editor 
                    apiKey='akdv6tedbfr7phl82c0bkcnj4mt6s3b1xfav3lx4gtrj5zza'
                    initialValue={capturaPostagem?.content}
                    init={{
                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                        toolbar: 'undo redo | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent',
                        min_height: 600,
                        skin: 'oxide-dark',
                        content_css: 'dark',
                        setup: (editor) => {
                            editor.on("change", () => {
                                const content = editor.getContent();
                                setValue('content', content); 
                            });
                        }
                    }}
                />
                {errors.content && <Typography variant='caption' sx={{ color: 'red' }}>{errors.content.message}</Typography>}
                
                <Box sx={{ marginTop: 2 }}>
                    <Typography variant='h6'>Tags da postagem</Typography>
                    {tags.map((tag, index) => (
                        <Typography key={index} component={Button} sx={{ display: 'inline-block', margin: 1, background: theme.palette.tertiary.main, color: theme.palette.primary.contrastText, textTransform: "full-size-kana"}}>
                            {tag}
                            <Icon sx={{ position: "absolute", zIndex: 1, top: "-15%", fontSize: "100%", color: "red" }} onClick={() => removeTags(index)}>close</Icon>
                        </Typography>
                    ))}
                </Box>

                <Box sx={{ display: "flex", margin: "2% 0%" }}>
                    <TextField
                        value={adicionaTag}
                        onChange={(ev) => setAdicionaTag(ev.target.value)}
                        label="Tags do post"
                        error={!!tagError || !!errors.tag}
                        helperText={tagError || errors.tag?.message}
                        sx={{ width: "auto", marginRight: 1 }}
                    />
                    <Button type='button' variant="contained" onClick={arrayTags} disabled={tags.length >= 5}>Adicionar Tag</Button>
                </Box>

                <TextField
                    {...register("imageLink")}
                    label="Link da imagem"
                    error={!!errors.imageLink}
                    helperText={errors.imageLink?.message}
                    sx={{ width: "100%", marginBottom: 2 }}
                />

                <Button type="submit" variant="contained" sx={{ marginTop: 2 }} disabled={isSubmitting}>
                    {isSubmitting ? "Enviando..." : id ? "Editar Post" : "Criar Post"}
                </Button>
            </form>

            {
                snackbarData && (<SnackbarTransform msg={snackbarData.message} erro={!snackbarData.success} />)
            }
        </LayoutAdministration>
    );
});
