import { CampoBusca, FooterPrincipal, MenuPrincipal } from "../../shared/components";
import { LayoutBase } from "../../shared/layout";
import { ContentCriarConta } from "./pages/ContentCriarConta";
import { ContentLogin } from "./pages/ContentLogin";
import { Routes, Route} from "react-router-dom";

export const Login = () => {

    return(
        <>
            <CampoBusca/>
            <MenuPrincipal/>

                <Routes>
                    <Route path="/entrar-em-uma-conta" element={
                        <LayoutBase title="Login">
                            <ContentLogin/>
                        </LayoutBase>
                    } />

                    <Route path="/criar-uma-nova-conta" element={ 
                        <LayoutBase title="Criar conta">
                            <ContentCriarConta/>
                            </LayoutBase> 
                    } />
                </Routes>

            <FooterPrincipal/>
        </>
    );
}