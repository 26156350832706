import { ExceptionError } from "../APIErrorConfig"
import { API } from "../APIAxiosConfig"

export interface ILoginTokenAccess{
    isValid: boolean;
    idUserValid: number;
    userValid: string;
}

interface IFormularioCadastro{
    name: string;
    email: string;
    password: string;
    number: string;
    cep: string;
    cidade: string;
    uf: string;
    bairro: string;
    logradouro: string;
    receberEmail?: boolean;
    concordaTermos: boolean;
}

// Fazendo login no site.
const postLogin = async (email: string, password: string): Promise<string | ExceptionError> => {
    try {
        const urlRelative = `/login`;
        const { data } = await API().post(urlRelative,{email, password});
        return data.token;
    }
    catch (error: any) {
        return new ExceptionError(error.msg || "Tentativa de login inválida, E-mail ou Senha errada!");
    }
}

// Confirmando token.
const tokenConfirm = async (verificaToken: string): Promise<ILoginTokenAccess | ExceptionError> => {
    try {
        const urlRelative = `/validate`;
        const {data} = await API().post(urlRelative,{verificaToken});
        return data;
    }
    catch (error: any) {
        return new ExceptionError(error.msg || "O Token é inválido!");
    }
}

// Criando nova conta.
const createAccount = async (criandoConta: IFormularioCadastro): Promise<string | ExceptionError> => {
    try {
        const urlRelative = "/created-account";
        const {data} = await API().post(urlRelative, criandoConta);
        return data;
    }
    catch (error: any) {
        return new ExceptionError(error.msg || "Não foi possível criar uma counta!");
    }
}

export const EndPointUser = {
    postLogin,
    tokenConfirm,
    createAccount
}