import React from "react";
import { Rotas } from "./routes";
import { AppThemeProvider } from "./shared/contexts/SwitchThemeContext";
import { OpenCloseSearchProvider } from "./shared/contexts/OpenAndCloseSearchContext";
import { ConfirmTokenUserContextProvider } from "./shared/contexts/ConfirmTokenUserContext";
import { HelmetProvider } from "react-helmet-async";

export const App: React.FC = () => {

  return (
    <HelmetProvider>
      <ConfirmTokenUserContextProvider>
        <AppThemeProvider>
          <OpenCloseSearchProvider>
            <Rotas></Rotas>
          </OpenCloseSearchProvider>
        </AppThemeProvider>
      </ConfirmTokenUserContextProvider>
    </HelmetProvider>
  );
}