import { Box, Button, Card, Checkbox, FormControlLabel, IconButton, InputAdornment, TextField, Typography, useTheme } from "@mui/material";
// Icones.
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";

import { NumberTransform, CepNumberTransform, BoxMessageErro} from "../../../shared/utils/index";

import { APIExterna, IViacep, EndPointUser } from "../../../shared/services/index";

import { ExceptionError } from "../../../shared/services";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from 'yup';

import { useConfirmErrorContext } from "../../../shared/contexts/ConfirmErrorContext";
import { PageHeaderTransform } from "../../../shared/utils/PageHeadTransform";

// Criando type para os dados do formulário.
type TFormularioCadastro = {
    nomeCompleto: string;
    email: string;
    senhaForm: string;
    confirmaSenhaForm: string;
    telefone: string;
    cep: string;
    cidade: string;
    uf: string;
    bairro: string;
    logradouro: string;
    receberEmail?: boolean;
    termosUso: boolean;
}

// Criando erros que podem ser gerados no cadastro.
const cadastroValidation = Yup.object().shape({
    nomeCompleto: Yup
        .string()
        .min(15, "Seu nome completo deve ter mais de 15 caracteres.")
        .max(55, "Seu nome completo e muito grande.")
        .required("Nome completo é um campo obrigatório."),
    email: Yup
        .string()
        .min(15)
        .max(100, "Seu e-mail é muito grande.")
        .email("Coloque um e-mail válido.")
        .required("E-mail é um campo obrigatório."),
    senhaForm: Yup
        .string()
        .min(8, "Sua senha precisa ter mais de 8 caracteres.")
        .required("Você precisa colocar uma senha."),
    confirmaSenhaForm: Yup
        .string()
        .oneOf([Yup.ref("senhaForm")], "As suas senhas estão diferentes uma da outra.")
        .required("Você precisa confirmar sua senha."),
    telefone: Yup
        .string()
        .min(11,"Está faltando números no telefone, confira se você inseriu o DDD.")
        .max(14, "O Telefone está errado, número a mais foi detectado.")
        .required("Telefone é um campo obrigatório."),
    cep: Yup
        .string()
        .min(9, "Está faltando números no CEP.")
        .max(9, "CEP está errado, número a mais foi detectado.")
        .required("O CEP é um campo obrigatório."),
    cidade: Yup
        .string()
        .required("Cidade é um campo obrigatório."),
    uf: Yup
        .string()
        .min(2,"UF está errado, deve ter no mínimo 2 letras representando a localidade.")
        .max(2,"UF está errado, deve ter no máximo 2 letras representando a localidade.")
        .required("UF é um campo obrigatório, coloque 2 letras que represente a cidade."),
    bairro: Yup
        .string()
        .required("Bairro é um campo obrigatório."),
    logradouro: Yup
        .string()
        .required("Logradouro é um campo obrigatório."),
    termosUso: Yup
        .bool()
        .oneOf([true],"Os termos de uso deve ser marcado para concluír a criação da conta.")
        .required("Para concluír com o cadastro esse campo precisa estar marcado e de acordo com os nossos termos listados.")
});

export const ContentCriarConta = () => {

    const urlAtual = useLocation();
    const theme = useTheme();

    //  Hooks de confirmaçao de criacao de conta -- Testar mensagem de success apos login
    const {openError, handleOpenError, handleCloseError} = useConfirmErrorContext();
    const [messageCriacaoConta, setMessageCriacaoConta] = useState({title: "", content:""});

    // Mostrar / Esconder = Senha.
    const [mostrarSenha, setMostrarSenha] = useState(false);

    const clickMostrarSenha = () => {
        setMostrarSenha(!mostrarSenha);
    }

    // Concordar com termos de uso de dados do site.
    const [euConcordo, setEuConcordo] = useState(false);
    
    const clickEuConcordoTermosUso = () => {
        setEuConcordo(!euConcordo);
    }
    
    // Concordar com recebimento de e-mails.
    const [receberEmail, setReceberEmail] = useState(false);
    
    const clickEuConcordoReceberEmail = () => {
        setReceberEmail(!receberEmail);
    }
    
    // Capturando dados da API Viacep a partir do CEP.
    const [capturaCep, setcapturaCep] = useState("");
    const [responseViacep, setResponseViacep] = useState<IViacep | null>();
    
    useEffect(() => {
        const infoAPI = async () => {
            try {
                const resultado = await APIExterna.viacep(capturaCep);

                if (resultado instanceof ExceptionError) {
                    console.log("CEP inválido!", resultado.message);
                    setResponseViacep(null);
                }
                else{
                    setResponseViacep(resultado);
                }
            }
            catch (error) {
                console.log("CEP inválido!", error);
                setResponseViacep(null);
            }
        };

        if(capturaCep){
            infoAPI();
        }
    },[capturaCep])

    // Usando o useForm para fazer validação do formulário.
    const { register, handleSubmit, setValue, formState: { errors } } = useForm<TFormularioCadastro>({
        resolver: yupResolver(cadastroValidation),
        defaultValues: {receberEmail: true}
    });

    // Effect para que os campos capturados do Viacep possam ser alterados.
    useEffect(() => {
        if(responseViacep?.cep || responseViacep?.bairro || responseViacep?.cidade || responseViacep?.logradouro || responseViacep?.uf){
            setValue("cep", responseViacep.cep);
            setValue("bairro", responseViacep.bairro);
            setValue("cidade", responseViacep.cidade);
            setValue("logradouro", responseViacep.logradouro);
            setValue("uf", responseViacep.uf);
        }
    },[responseViacep?.cep, responseViacep?.bairro, responseViacep?.cidade, responseViacep?.logradouro, responseViacep?.uf, setValue]);

    // BOTAO CADASTRADO AJUSTADO SO TESTAR DEPOIS.
    const concluirCadastro = async (data: any) => {
        try {
            await EndPointUser.createAccount(
                {
                    name: data.nomeCompleto,
                    email: data.email,
                    password: data.confirmaSenhaForm,
                    number: data.telefone,
                    cep: data.cep,
                    cidade: data.cidade,
                    uf: data.uf,
                    bairro: data.bairro,
                    logradouro: data.logradouro,
                    receberEmail: data.receberEmail,
                    concordaTermos: data.termosUso,
                }
            ).then((resultado) => {
                if(resultado instanceof ExceptionError){
                    console.log("Erro no envio das informações.");
                    handleOpenError();
                    setMessageCriacaoConta({title: "Ocorreu um erro na criaçao da conta!", content: "Clique em OK e tente fazer a criaçao novamente."})
                }
                else{
                    console.log("Conta criada!");
                    handleOpenError();
                    setMessageCriacaoConta({title: "Conta criada com sucesso!", content: "Clique em OK e faça login em sua nova conta."})
                }
            })
        }
        catch (error) {
            handleOpenError();
            setMessageCriacaoConta({title: "Nao conseguimos criar sua conta!", content: "Ocorreu o seguinte erro:" + error});
        }
    }

    return(
        <>
            <Card sx={{m: "1% 30%", p: "4%", display: "flex", flexDirection: "column", rowGap: 4, "@media (max-width: 1150px)":{m: "1% 15%"}, "@media (max-width: 600px)":{m: "1%"}}}>
                
                <Typography variant="h6" color={theme.palette.secondary.contrastText}>Faça seu cadastro</Typography>

                <Typography variant="body2" color={theme.palette.secondary.contrastText}>
                    Já possui uma conta?
                    <Link to={"/login/entrar-em-uma-conta"}>
                    <Typography variant="caption" color={theme.palette.secondary.contrastText} ml={1} sx={{textDecoration:"underline", cursor:"pointer", ":hover":{color:`${theme.palette.primary.main}`}}}>Entrar</Typography>
                    </Link>.
                </Typography>

                <TextField
                    label="Nome completo"
                    {...register("nomeCompleto")}
                    id="nomeCompleto"
                    name="nomeCompleto"
                    error={!!errors.nomeCompleto}
                    helperText={errors.nomeCompleto?.message}
                    placeholder="Ex: Fulano de tal da Silva"
                    sx={{ width: "100%"}}
                />

                <TextField
                    label="E-mail"
                    {...register("email")}
                    id="email"
                    name="email"
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    placeholder="Ex: fulano.de.tal@email.com"
                    sx={{ width: "100%"}}
                />

                <TextField
                    label="Senha"
                    {...register("senhaForm")}
                    id="senhaForm"
                    name="senhaForm"
                    error={!!errors.senhaForm}
                    helperText={errors.senhaForm?.message}
                    type={mostrarSenha ? "text" : "password"}
                    InputProps={{
                        endAdornment:(
                            <InputAdornment position="end">
                                <IconButton aria-label="Mostrar senha" onClick={clickMostrarSenha} edge="end">
                                    {mostrarSenha ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    sx={{width: "100%"}}
                />

                <TextField
                    label="Repita a senha"
                    {...register("confirmaSenhaForm")}
                    id="confirmaSenhaForm"
                    name="confirmaSenhaForm"
                    error={!!errors.confirmaSenhaForm}
                    helperText={errors.confirmaSenhaForm?.message}
                    type={mostrarSenha ? "text" : "password"}
                    InputProps={{
                        endAdornment:(
                            <InputAdornment position="end">
                                <IconButton aria-label="Mostrar senha" onClick={clickMostrarSenha} edge="end">
                                    {mostrarSenha ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                    sx={{width: "100%"}}
                />

                <TextField
                    label="Telefone"
                    {...register("telefone")}
                    id="telefone"
                    name="telefone"
                    error={!!errors.telefone}
                    helperText={errors.telefone?.message}
                    placeholder="Ex: (00)00000-0000"
                    sx={{ width: "100%"}}
                    onChange={(evt) => {
                        setValue("telefone", NumberTransform(evt.target.value));
                    }}
                />

                <Box display={"flex"} columnGap={2}>
                    <TextField
                        label="CEP"
                        {...register("cep")}
                        id="cep"
                        name="cep"
                        error={!!errors.cep}
                        helperText={errors.cep?.message}
                        placeholder="Ex: 00000-000"
                        sx={{ width: "30%"}}
                        onChange={(evt) => {
                            setValue("cep", CepNumberTransform(evt.target.value));
                            if(evt.target.value.length === 9) {
                                setcapturaCep(evt.target.value);
                            }
                        }}
                    />

                    <TextField
                        label="Cidade"
                        {...register("cidade")}
                        id="cidade"
                        name="cidade"
                        error={!!errors.cidade}
                        helperText={errors.cidade?.message}
                        placeholder="Ex: São Paulo"
                        sx={{ width: "55%"}}
                    />

                    <TextField
                        label="UF"
                        {...register("uf")}
                        id="uf"
                        name="uf"
                        error={!!errors.uf}
                        helperText={errors.uf?.message}
                        placeholder="SP"
                        sx={{ width: "15%"}}
                    />
                </Box>

                <TextField
                    label="Bairro"
                    {...register("bairro")}
                    id="bairro"
                    name="bairro"
                    error={!!errors.bairro}
                    helperText={errors.bairro?.message}
                    placeholder="Ex: Parque das Flores Azuis"
                    sx={{ width: "100%"}}
                />

                <TextField
                    label="Logradouro"
                    {...register("logradouro")}
                    id="logradouro"
                    name="logradouro"
                    error={!!errors.logradouro}
                    helperText={errors.logradouro?.message}
                    placeholder="Ex: Rua Miguél de Plácido"
                    sx={{ width: "100%"}}
                />
                
                <Box>
                    <FormControlLabel control={
                        <Checkbox {...register("receberEmail")} id="receberEmail" name="receberEmail" onChange={clickEuConcordoReceberEmail}/>
                    } label="Sim, quero receber e-mails com novidades."/>

                    <FormControlLabel control={
                        <Checkbox {...register("termosUso")} id="termosUso" name="termosUso" onChange={clickEuConcordoTermosUso}/>
                    } label="Eu concordo com os termos de uso de dados."/>
                    
                    <Typography variant="caption" display={"block"} ml={4} color="red">{errors.termosUso?.message}</Typography>
                </Box>

                <Button type="submit" variant="contained" disabled={!euConcordo} sx={{ marginTop: 2 }} onClick={handleSubmit(concluirCadastro)}>Criar conta</Button>
            </Card>

            <PageHeaderTransform 
                    titlePage={"Criar conta - AlexsandroCS Portifólio e Blog"}
                    resumePage={"Crie sua conta no site AlexsandroCS e use todos os recursos disponíveis."}
                    urlPage={urlAtual.pathname}
                />

            {
                messageCriacaoConta.title && (<BoxMessageErro openBox={openError} titleError={messageCriacaoConta.title} contentError={messageCriacaoConta.content} closeBox={handleCloseError}/>)
            }
        </>
    );
}