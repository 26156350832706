import { createContext, useContext, useState } from "react";

interface IOpenCloseSearch{
    pesquisaAberta: boolean;
    abrirPesquisa: () => void;
    fecharPesquisa: () => void;
}

interface IOpenCloseSearchProps{
    children: React.ReactNode;
}

const OpenCloseSearch = createContext<IOpenCloseSearch>({} as IOpenCloseSearch);

export const OpenCloseSearchProvider: React.FC<IOpenCloseSearchProps> = ({children}) => {

    const [pesquisaAberta, setPesquisa] = useState(false);

    // Função que abre e fecha a pesquisa.
    const abrirPesquisa = () => setPesquisa(true);
    const fecharPesquisa = () => setPesquisa(false);

    return(
        <OpenCloseSearch.Provider value={{pesquisaAberta, abrirPesquisa, fecharPesquisa}}>
            {children}
        </OpenCloseSearch.Provider>
    );
}

// Criando hook para usar o context nos componentes.
export const useSearch = () => {
    const context = useContext(OpenCloseSearch);
    if (context === undefined) {
        throw new Error('Ocorreu algum erro no campo de busca.');
    }
    return context;
}