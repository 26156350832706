import { Box, Grid, Icon, LinearProgress, Pagination, Typography, styled, useTheme } from "@mui/material";
import { useEffect, useState, useMemo } from 'react';
import { EndPointPostagem, IPostPagination } from '../../../shared/services/API/connection/Postagens';
import { ExceptionError } from "../../../shared/services";
import { useParams, useNavigate } from "react-router-dom";
import { trataData} from "../../../shared/utils";
import { BoxMessageErro } from "../../../shared/utils/BoxMessageErro";
import { useConfirmErrorContext } from "../../../shared/contexts/ConfirmErrorContext";

export const ContentPrincipal = () => {

    // Imagem da postagem.
    const ImagemPostagem = styled("img")(({theme}) => ({
        // height: {xs: 180, sm: 300, md: 500, lg: 1400, xl: 2500},
        width: "100%",
        height: "auto",
        objectFit: "cover",
        maxHeight: "300px",
        borderRadius: "3px"
    }));

    // Tags da postagem.
    const TagPostagem = styled(Typography)(({theme}) => ({
        padding: "5px",
        margin: "2px 6px 2px 0px",
        fontSize:"clamp(0.5rem, 1vw + 0.5rem, 0.6rem)",
        fontWeight: "900",
        fontFamily: "Alata, sans-serif",
        borderRadius: "3px",
        borderColor: `${theme.palette.tertiary.main}`,
        whiteSpace: "nowrap"
    }));

    // Link da postagem.
    const LinkPostagem = styled("a")(({theme}) => ({
        display: "inline-flex",
        justifyContent: "start",
        marginLeft: "2%",
        cursor: "pointer",
        color: `${theme.palette.text.primary}`,
        textDecoration: "none",
        fontSize:"clamp(0.8rem, 1vw + 0.8rem, 0.9rem)",
        fontWeight: "900",
        fontFamily: "Alata, sans-serif",
        "&:hover":{
            textDecoration: "underline"
        }
    }));

    const theme = useTheme();

    const [isLoading, setIsLoading] = useState(true);
    const {openError, handleCloseError} = useConfirmErrorContext();
    const [messageErro, setMessageErro] = useState({title: "", content:""});

    // Captura o número da página da URL.
    const { pg } = useParams<{ pg: string }>();
    // Navegando entre as páginas.
    const navigate = useNavigate();
    // Endpoint: Capturando todas as postagens para postar na página principal.
    const [postagem, setListaPostagem] = useState<IPostPagination>({ post: [], totalPosts: 0, totalPages: 0 });
    
    const pageNumber = useMemo(() => {
        // Subtrai 1 para alinhar com a indexação baseada em 0.
        return Number(pg) ? Number(pg) - 1 : 0;
    }, [pg]);

    // Função que salva os post no localStorage.
    const savePostLocalStorage = (posts: IPostPagination) => {
        const filtroPostagem = posts.post.map((post) => ({
            id: post.id,
            title: post.title,
            resumo: post.resumo,
            tag: post.tag,
            date: post.date,
            imageLink: post.imageLink,
            nameCreator: post.nameCreator,
            link: post.link
        }));
    
        const filtroDados = {
            post: filtroPostagem,
            totalPosts: posts.totalPosts,
            totalPages: posts.totalPages,
        };

        localStorage.setItem("posts", JSON.stringify(filtroDados));
    };

    // Função que captura os post no localStorage.
    const searchPostLocalStorage = (): IPostPagination | null => {
        const postSave = localStorage.getItem("posts");
        return postSave ? JSON.parse(postSave) : null;
    }

    // Função que captura os posts na API e atualiza o localStorage.
    const searchPostAPI = (pageNumber: number) => {
        setIsLoading(true);

        EndPointPostagem.getPosts(pageNumber).then((response) => {
            if(response instanceof ExceptionError){
                setMessageErro({
                    title: "Postagens não foi encontradas.",
                    content: "Atualize a página, se o erro persistir volte mais tarde!"
                });
                setIsLoading(false);
            }
            else{
                setListaPostagem(response);
                savePostLocalStorage(response);
                setIsLoading(false);
            }
        });
    };

    // Efeito quando o component é executado toda vez que a página é carregada.
    useEffect(() => {
        const postInLocalStorage = searchPostLocalStorage();

        if(postInLocalStorage){
            setListaPostagem(postInLocalStorage);
            setIsLoading(false);
        }
        else{
            searchPostAPI(pageNumber);
        }

        const interval = setInterval(() => {
            searchPostAPI(pageNumber);
        },300000);

        return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber]);

    // Função de separação de tags.
    const separaTag = (tag: string) => {
        return tag.split(", ").map((tagElement) => (
            <TagPostagem border={1} key={tagElement}>
                {tagElement}
            </TagPostagem>
        ));
    };

    return (
        <>
            {
                isLoading ? ( 
                    <>
                        <Typography>Carregando postagens...</Typography>
                        <LinearProgress variant="indeterminate" />
                    </>
                ) : (
                    postagem.post.map((postagem) => (
                        <Box key={postagem.id} sx={{padding: "28px", borderRadius: "4px", boxShadow: 3, mb: 4, transition: "background 0.5s ease-in-out", background: `linear-gradient(to right, ${theme.palette.background.paper} 0%, ${theme.palette.background.paper} 50%, ${theme.palette.tertiary.light} 66%, ${theme.palette.tertiary.dark} 160%)`, backgroundSize: "200% 100%", backgroundPosition: "left bottom",  backgroundRepeat: "no-repeat",":hover":{backgroundPosition: "right bottom"}, [theme.breakpoints.down(900)]:{padding: "12px", background: `linear-gradient(to bottom, ${theme.palette.background.paper} 0%, ${theme.palette.background.paper} 50%, ${theme.palette.tertiary.light} 66%, ${theme.palette.tertiary.dark} 160%)`, backgroundSize: "100% 200%", backgroundPosition: "top center",":hover":{backgroundPosition: "bottom center"}}}}>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item xs={12} md={4}>
                                    <ImagemPostagem src={postagem.imageLink}/>
                                </Grid>

                                <Grid item xs={12} md={8}>
                                    <Typography ml={2} fontWeight={"bold"} sx={{color: `${theme.palette.secondary.contrastText}`}} variant="h5" component="h2" gutterBottom>
                                        {postagem.title}
                                    </Typography>

                                    <Box sx={{ display: "flex", gap:"3px", flexWrap: "wrap", mb: 2, ml: 2, color: `${theme.palette.secondary.contrastText}`}} >
                                        {separaTag(postagem.tag)}
                                    </Box>

                                    <Typography display={"flex"} flexDirection={"row"} ml={2} variant="body1" gutterBottom sx={{color: `${theme.palette.secondary.contrastText}`}}>
                                        <Typography fontWeight={"bold"} mr={1} variant="body1">Data:</Typography>
                                        {trataData(postagem.date)}
                                    </Typography>

                                    <Typography display={"flex"} flexDirection={"row"} ml={2} variant="body1" gutterBottom sx={{color: `${theme.palette.secondary.contrastText}`}}>
                                        <Typography fontWeight={"bold"} mr={1} variant="body1">Postado por:</Typography>
                                        {postagem.nameCreator}
                                    </Typography>

                                    <Typography sx={{display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis", ml: 2, color: `${theme.palette.secondary.contrastText}`}}>{postagem.resumo}</Typography>

                                    <LinkPostagem href={"/postagem/"+postagem.link}>
                                        Ver postagem <Icon>arrow_right_alt</Icon>
                                    </LinkPostagem>
                                </Grid>
                            </Grid>
                        </Box>

                    ))
                )
            }
            
            {postagem.totalPosts > 10 && (
                <Pagination
                    sx={{ margin: "0 auto" }}
                    page={pageNumber + 1}
                    count={postagem.totalPages}
                    variant="text"
                    shape="rounded"
                    color="primary"
                    onChange={(evt, pageAtual) => {
                        // Navegando para URL /inicio/{número}.
                        navigate(`/inicio/${pageAtual}`); 
                    }}
                />
            )}

            {messageErro.title && (<BoxMessageErro openBox={openError} titleError={messageErro.title} contentError={messageErro.content} closeBox={handleCloseError}/>)}
        </>
    );
}