import {Box, Button, ButtonGroup, styled} from '@mui/material';

// import Logo.
import { Link } from 'react-router-dom';
import ACSLogo from "../../images/Logo.png";
import { MenuOpcoes } from './MenuOpcoes';
import { useVerifyTokenUserLogado } from '../../contexts/ConfirmTokenUserContext';

export const MenuPrincipal: React.FC = () => {

    // Todo o menu.
    const BoxMenuPrincipal = styled(Box)(({theme}) => ({
        width: "100%",
        height: "auto",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        background: "linear-gradient(-50deg, #1e1e1e 20%, #3a4655 60%, #373737 110%)",
        [theme.breakpoints.down("md")]:{
            flexDirection: "column",
            alignItems: "center"
        }
    }));

    // Logotipo e Título do menu.
    const BoxLogoTitulo = styled(Box)(({theme}) => ({
        display: "flex",
        flex: "1",
        alignItems: "center"
    }));

    // Logotipo do menu.
    const LogoTipoMenuPrincipal = styled("img")(({theme}) => ({
        height: "80px",
        margin: "2%",
        [theme.breakpoints.down(1420)]:{
            height: "60px"
        },
        [theme.breakpoints.down("md")]:{
            margin: "0%",
            Padding: "0%"
        }
    }));

    // Título 1 do menu.
    const LogoTituloP = styled("p")(({theme}) => ({
        color: "#9b9b9b",
        fontSize: "xx-large",
        fontFamily: "Alata,sans-serif",
        fontWeight: "bold",
        [theme.breakpoints.down(1450)]:{
            fontSize: "x-large"
        },
    }));

    // Título 2 do menu.
    const LogoTituloS = styled("span")(({theme}) => ({
        color: "#fc6005",
        fontSize: "100%",
        [theme.breakpoints.down("md")]:{
            //fontSize: "22px",
        }
    }));
 
    // Menu onde fica os botões.
    const BoxMenuPaginas = styled(Box)(({theme}) => ({
        display: "flex",
        flex: "1",
        alignItems: "center",
    }));

    // Botões de páginas.
    const BotoesMenuPaginas = styled(Button)(({theme}) => ({
        width: "25vh",
        borderColor: `${theme.palette.tertiary.light} !important`,
        [theme.breakpoints.down(1065)]:{
            width: "20vh",
            padding: "5px"
        },
        [theme.breakpoints.down("sm")]:{
            width: "15vh",
            padding: "3px"
        },
        [theme.breakpoints.down(400)]:{
            width: "12vh"
        },
        [theme.breakpoints.down(320)]:{
            width: "100%"
        },
    }));

    // Menu de opções: cor do Tema / ...
    const BoxMenuOpcoes = styled(Box)(({theme}) => ({
        display: "flex",
        flex: "1",
        alignItems: "center",
        justifyContent:"flex-end",
        [theme.breakpoints.down("md")]:{
            margin: "2vh"
        }
    }));

    const {tokenIsValid} = useVerifyTokenUserLogado();

    // Grupo de botões.
    const botoesMenuPaginas = [
        <Link to="/inicio" key="inicio">
            <BotoesMenuPaginas>Inicio</BotoesMenuPaginas>
        </Link>,
        <Link to="/sobre" key="sobre">
            <BotoesMenuPaginas>Sobre</BotoesMenuPaginas>
        </Link>,
        tokenIsValid === false ? (
            <Link to="/login/entrar-em-uma-conta" key="login">
                <BotoesMenuPaginas>Login</BotoesMenuPaginas>
            </Link>
        ) : (
            <Link to="/administracao" key="administration">
                <BotoesMenuPaginas>Administração</BotoesMenuPaginas>
            </Link>
        )
    ];
    
    return(
        <>
            <BoxMenuPrincipal>
                <BoxLogoTitulo>
                    <LogoTipoMenuPrincipal src={ACSLogo} />
                
                    <LogoTituloP>
                        <LogoTituloS>A</LogoTituloS>
                        lexsandro
                        <LogoTituloS>CS</LogoTituloS>
                    </LogoTituloP>
                </BoxLogoTitulo>

                <BoxMenuPaginas>
                    <ButtonGroup variant="contained" size="large">{botoesMenuPaginas}</ButtonGroup>
                </BoxMenuPaginas>

                <BoxMenuOpcoes>
                    <MenuOpcoes/>
                </BoxMenuOpcoes>
            </BoxMenuPrincipal>
        </>
    );
}