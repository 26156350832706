import React from "react";
import { Helmet } from "react-helmet-async";

type PageHeaderTransformProps = {
    titlePage: string;
    resumePage: string;
    linkImagePage?: string;
    urlPage?: string;
    tagsPage?: string;
    dataPage?: Date;
}

export const PageHeaderTransform: React.FC<PageHeaderTransformProps> = (
    {
        titlePage, 
        resumePage, 
        linkImagePage="", 
        urlPage="",
        tagsPage="",
        dataPage,
    }) => {
    
    const tagsPadrao = "Blog, Computadores, informática, Linux 2024, Sistema operacional, Programação, Lógica de Programação, Segurança de dados, Arquitetura de Software, Blog tec, tecnologia 2024";

    return(
        <>
            <Helmet>
                <title>{titlePage} - AlexsandroCS Portifólio e Blog</title>
                <meta name="description" content={resumePage}/>
                
                {tagsPage != null || tagsPage === "" || tagsPage === undefined? tagsPage = tagsPage + tagsPadrao : tagsPage = tagsPadrao}
                <meta name="keywords" content={tagsPage}/>

                <meta name="author" content="Alexsandro da Conceição Soares"/>
                <link rel="me" href="https://www.linkedin.com/in/alexsandrocs"/>
                <link rel="me" href="https://github.com/AlexsandroCS"/>

                <meta property="og:title" content={titlePage}/>
                <meta property="og:type" content="article"/>
                <meta property="og:description" content={resumePage}/>
                <meta property="og:image" content={linkImagePage}/>
                <meta property="og:url" content={"https://www.alexsandrocs.com"+urlPage}/>
                <meta property="og:site_name" content="AlexsandroCS Blog e Portifólio - Blog de tecnologia no mundo TI."/>
                <meta property="article:published_time" content={String(dataPage)}/>

                <link rel="canonical" href={"https://www.alexsandrocs.com"+urlPage}/>
            </Helmet>
        </>
    );
}