import { createContext, useContext, useEffect, useState } from "react";
import { EndPointUser } from "../services/API/connection/User";
import { ExceptionError } from "../services";

interface IConfirmTokenUserData{
    tokenIsValid: boolean;
    tokenIdUser: number;
    tokenNameUser: string;
    logout: () => void;
}

interface IConfirmTokenUserProps {
    children: React.ReactNode;
}

const ConfirmTokenUserContext = createContext<IConfirmTokenUserData>({} as IConfirmTokenUserData);

export const ConfirmTokenUserContextProvider: React.FC<IConfirmTokenUserProps> = ({children}) => {

    const [userToken, setUserToken] = useState<IConfirmTokenUserData>({tokenIsValid: false, tokenIdUser: 0, tokenNameUser: "", logout(){},});
    const capturaToken = localStorage.getItem('token');
    
    useEffect(() => {
        if(capturaToken !== null && capturaToken !== "" && capturaToken !== undefined){
            EndPointUser.tokenConfirm(capturaToken).then((response) => {
                if(response instanceof ExceptionError){
                    
                }
                else{
                    setUserToken({tokenIsValid: response.isValid, tokenIdUser: response.idUserValid, tokenNameUser: response.userValid, logout: clickLogout});
                }
            });
        }
    },[capturaToken]);

    const clickLogout = () => {
        return localStorage.removeItem("token");
    }

    return(
        <ConfirmTokenUserContext.Provider value={userToken}>
            {children}
        </ConfirmTokenUserContext.Provider>
    );
    
}

export const useVerifyTokenUserLogado = () => {
    return useContext(ConfirmTokenUserContext);
}