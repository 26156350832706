import { CampoBusca, FooterPrincipal, MenuPrincipal } from "../../shared/components";
import { LayoutBase } from "../../shared/layout";
import { PageHeaderTransform } from "../../shared/utils/PageHeadTransform";
import { ContentPrincipal } from "./components/ContentPrincipal";

export const Inicio = () => {

    return(
        <>
            <PageHeaderTransform 
                titlePage={"Blog inicio"}
                resumePage={"AlexsandroCS veja tutoriais completos de instalação ou utilização de uma ferramenta específica de tecnologia e aplique de forma fácil nos seus projetos pessoais, com uma leitura rápida e mini tutoriais com imagens ou vídeos você consegue adquirir um conhecimento de forma fácil e prática."}
            />
            <CampoBusca/>
            <MenuPrincipal/>

            <LayoutBase title="Postagens">
                <ContentPrincipal/>
            </LayoutBase>

            <FooterPrincipal/>
        </>
    );
}
