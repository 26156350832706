import { useLocation } from "react-router-dom";
import { CampoBusca, FooterPrincipal, MenuPrincipal } from "../../shared/components";
import { LayoutBase } from "../../shared/layout";
import { PageHeaderTransform } from "../../shared/utils/PageHeadTransform";
import { ContentSobre } from "./components/ContentSobre";

export const Sobre = () => {

    const urlAtual = useLocation();

    return (
        <>
            <PageHeaderTransform 
                titlePage={"Sobre - AlexsandroCS Portifólio e Blog"}
                resumePage={"Saiba um pouco sobre AlexsandroCS, suas skills e redes sociais."}
                urlPage={urlAtual.pathname}
            />
            <CampoBusca/>
            <MenuPrincipal/>

            <LayoutBase title="Sobre - AlexsandroCS">
                <ContentSobre/>
            </LayoutBase>
            
            <FooterPrincipal/>
        </>
    );
}