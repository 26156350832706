import { styled, Box, Icon, Divider, Typography, LinearProgress } from '@mui/material';
import React, { useState, useEffect, useCallback } from 'react';
import { useSearch } from '../../contexts/OpenAndCloseSearchContext';
import { EndPointPostagem } from '../../services/API/connection/Postagens';
import { IListPost } from '../../services/API/connection/Postagens';
import { ExceptionError } from '../../services';
import { useDebounce } from '../../hooks';

// Box Principal.
const BoxPrincipalSearch = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,.7)",
    position: "fixed",
    zIndex: "999",
    justifyContent: "center",
    alignItems: "center"
}));

// Box Componentes.
const BoxComponentesSearch = styled(Box)(({ theme }) => ({
    width: "90%",
    display: "flex",
    alignItems: "center",
    background: `${theme.palette.secondary.dark}`,
}));

// Input Search.
const InputSearch = styled("input")(({ theme }) => ({
    width: "100%",
    fontSize: "100%",
    padding: "1%",
    border: "none",
    backgroundColor: `${theme.palette.secondary.dark}`,
    color: "#fff",
    '&:focus': {
        outline: "none",
    },
    '&::placeholder': {
        fontStyle: 'italic',
    }
}));

// Ícones.
const IconSearch = styled(Icon)(({ theme }) => ({
    fontSize: "2rem",
    color: "#fff",
    padding: "1%"
}));

// Box Componentes Resultados.
const BoxComponentesResult = styled(Box)(({ theme }) => ({
    width: "90%",
    height: "80%",
    overflow: "scroll",
    background: `${theme.palette.secondary.dark}`,
}));

// Box Base Resultado.
const BoxBaseResultado = styled(Box)(({ theme }) => ({
    width: "auto",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "1%",
    transition: "background 0.5s ease-in-out",
    background: `linear-gradient(to right, ${theme.palette.secondary.dark} 0%, ${theme.palette.secondary.dark} 50%, rgba(17, 17, 17, 0.46) 66%, rgba(17, 17, 17, 0.46) 160%)`,
    backgroundSize: "200% 100%",
    backgroundPosition: "left bottom",
    backgroundRepeat: "no-repeat",
    "&:hover": {
        backgroundPosition: "right bottom",
        cursor: "pointer"
    }
}));

// Imagem Postagem.
const ImagemPostagem = styled("img")(({ theme }) => ({
    width: "100%",
    height: "100%",
    objectFit: "fill",
    borderRadius: "4px",
}));

// Link da postagem.
const LinkPostagem = styled("a")(({theme}) => ({
    display: "inline-flex",
    cursor: "pointer",
    color: `${theme.palette.text.primary}`,
    textDecoration: "none",
    fontSize:"clamp(0.8rem, 1vw + 0.8rem, 0.9rem)",
    fontWeight: "900",
    fontFamily: "Alata, sans-serif",
    "&:hover":{
        textDecoration: "underline"
    }
}));

// Propriedades da busca.
interface ICampoBuscaProps {
    textoBusca?: string;
    mudarTextoBusca?: (novaBusca: string) => void;
    isLoading?: boolean;
}

export const CampoBusca: React.FC<ICampoBuscaProps> = React.memo(({ textoBusca = "", mudarTextoBusca, isLoading = false }) => {
    const [inputValue, setInputValue] = useState(textoBusca);
    const [resultados, setResultados] = useState<IListPost[]>([]);
    const [loading, setLoading] = useState(isLoading);
    const { pesquisaAberta, fecharPesquisa } = useSearch();
    const { debounce } = useDebounce();

    // Desabilitar a barra de rolagem quando o campo busca estiver on.
    useEffect(() => {
        if(pesquisaAberta){
            document.body.style.overflow = "hidden";
        }
        return () => {
            document.body.style.overflow = "";
        };
    },[pesquisaAberta]);

    useEffect(() => {
        setInputValue(textoBusca);
    }, [textoBusca]);

    const handleChange = useCallback(async (evento: React.ChangeEvent<HTMLInputElement>) => {
        const value = evento.target.value;
        setInputValue(value);
        mudarTextoBusca?.(value);

        // Iniciando loading quando o debounce começar.
        setLoading(true);

        debounce(async () => {
            if (value) {
                try {
                    const resultado = await EndPointPostagem.getSearchPosts(value);
    
                    if (resultado instanceof ExceptionError) {
                        console.error("Erro ao buscar postagens:", resultado.message);
                        setResultados([]);
                    } else {
                        setResultados(resultado);
                    }
                } catch (error) {
                    console.error("Erro ao buscar postagens:", error);
                    setResultados([]);
                }
            } else {
                setResultados([]);
            }
            // Para o loading quando o debounce finaliza.
            setLoading(false);
        })

    }, [debounce, mudarTextoBusca]);
    

    if (!pesquisaAberta) {
        return null;
    }

    const htmlStyleReset = {
        h1: { all: "unset" },  // Remove todos os estilos padrão de h1
        h2: { all: "unset" },
        h3: { all: "unset" },
        h4: { all: "unset" },
        h5: { all: "unset"},
        h6: { all: "unset"}
    };

    return (
        <BoxPrincipalSearch>
            <BoxComponentesSearch>
                <IconSearch>search</IconSearch>
                <InputSearch placeholder="Procurar por ..." value={inputValue} onChange={handleChange}/>
                <IconSearch onClick={fecharPesquisa} sx={{ cursor: "pointer", padding: "0", marginRight: "1%", ":hover": { color: "red" } }}>close</IconSearch>
            </BoxComponentesSearch>

            <Divider sx={{ borderBottomWidth: "thick" }} />

            <BoxComponentesResult>
                
                {loading && <LinearProgress variant="indeterminate" />}

                {resultados.map((resultado, index) => (
                    <BoxBaseResultado key={index} sx={{ borderBottom: 1 }}>
                        <Box sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" }, alignItems: "center", justifyContent: "space-between"}}>
                            
                            <Box sx={{ width: "160px", height: "16vh", flex: "none", display: { xs: "none", sm: "block" }}}>
                                <ImagemPostagem sx={{objectFit: "cover"}} src={resultado.imageLink} />
                            </Box>

                            <Box sx={{ flex: "1", ml: { sm: 2 }, width: "100%", maxWidth: "100%", overflow: "hidden"}}>
                                <Typography sx={{ color: "#fff", fontSize: "clamp(0.5rem, 0.5vw + 1rem, 1rem)", fontWeight: "bolder"}}>
                                    {resultado.title}
                                </Typography>

                                <Typography sx={{...htmlStyleReset, color: "#fff", fontSize: "clamp(0.5rem, 0.5vw + 1rem, 1rem)", display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical", overflow: "hidden", textOverflow: "ellipsis"}} variant="body1" paragraph dangerouslySetInnerHTML={{ __html: resultado.content }}/>

                                <LinkPostagem href={"/postagem/"+resultado.link}>
                                    Ver postagem <Icon>arrow_right_alt</Icon>
                                </LinkPostagem>
                            </Box>
                        </Box>

                        <Box sx={{ marginLeft: "0.5%", color: "#ccc" }}>
                            <Typography component="span" variant="body2" fontSize="clamp(0.5rem, 1vw + 0.6rem, 0.65rem)" fontWeight={"bold"}>
                                {new Date(resultado.date).toLocaleDateString()}
                            </Typography>
                        </Box>
                    </BoxBaseResultado>
                ))}

            </BoxComponentesResult>
        </BoxPrincipalSearch>
    );
});
