import { useCallback, useRef } from "react"

// Fazendo com que a busca passe a ser 3 milisegundos na requisição ao Backend.
export const useDebounce = () => {

    const deboucing = useRef<NodeJS.Timeout>();

    const debounce = useCallback((func: () => void) => {

        if(deboucing.current){
            clearTimeout(deboucing.current);
        }
        deboucing.current = setTimeout(() => func(), 3000);
    },[])

    return { debounce }
}