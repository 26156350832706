import axios from "axios"
import { ExceptionError } from "../APIErrorConfig";

// Interface para capturar dados da API Viacep.
export interface IViacep{
    cep: string;
    logradouro: string;
    bairro: string;
    cidade: string;
    uf: string;
}

const viacep = async (cep: string): Promise<IViacep | ExceptionError> => {
    try {
        const resultado = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        
        return {
            cep: resultado.data.cep,
            logradouro: resultado.data.logradouro,
            bairro: resultado.data.bairro,
            cidade: resultado.data.estado,
            uf: resultado.data.uf
        }
    }
    catch (error: any) {
        return new ExceptionError(error.msg || "O CEP não é valido!");
    }
}

export const APIExterna = {
    viacep
}