import { useLocation, useParams } from "react-router-dom";
import { CampoBusca, FooterPrincipal, MenuPrincipal } from "../../shared/components";
import { LayoutBase } from "../../shared/layout";
import { ContentPostagem } from './components/ContentPostagem';
import { useEffect, useState } from "react";
import { EndPointPostagem, IListPost } from "../../shared/services/API/connection/Postagens";
import { ExceptionError } from "../../shared/services";
import { LinearProgress, Typography } from '@mui/material';
import { PageHeaderTransform } from '../../shared/utils/PageHeadTransform';

export const Postagem = () => {

    // Capturando a URL Atual da página.
    const { urlPage } = useParams<{ urlPage: string }>();
    const urlAtual = useLocation();

    // Local onde os dados capturados da chamada da API serão guardados.
    const [ conteudoPost, setConteudoPost ] = useState<IListPost | ExceptionError>();

    // Fazendo a chamada da API com parâmetro capturado da URL.
    useEffect(() => {
        if(urlPage){
            EndPointPostagem.getPostName(urlPage).then((resultadoPostagem) => {
                setConteudoPost(resultadoPostagem);
            })
        }
    }, [urlPage]);

    const validPostagem = () => {
        if(!conteudoPost){
            return <LayoutBase title={""}>
                        <Typography>Carregando Postagem...</Typography>
                        <LinearProgress variant="indeterminate" />
                    </LayoutBase>
        }

        if(conteudoPost instanceof ExceptionError){
            return <LayoutBase title={"ERRO FATAL!"}>
                        <Typography>Erro interno, tente novamente mais tarde!</Typography>
                    </LayoutBase>
        }

        return  <LayoutBase title={conteudoPost.title}>
                    <PageHeaderTransform 
                        titlePage={conteudoPost.title}
                        resumePage={conteudoPost.resumo}
                        linkImagePage={conteudoPost.imageLink}
                        urlPage={urlAtual.pathname}
                        tagsPage={conteudoPost.tag}
                        dataPage={conteudoPost.date}
                    />

                    <ContentPostagem
                        title={conteudoPost.title}
                        content={conteudoPost.content}
                        date={conteudoPost.date}
                        nameCreator={conteudoPost.nameCreator}
                    />
                </LayoutBase>;
    }
    
    return(
        <>
            <CampoBusca/>
            <MenuPrincipal/>
            
            {validPostagem()}
            
            <FooterPrincipal/>
        </>
    );
}